const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'change' }
const baseSelect = {
  required: true, message: 'Please select options', trigger: 'change'
}
const emailValidator = {
  required: true, type: 'email', message: 'Please input correct email', trigger: 'blur'
}
export default {
  entity_director_postion: [
    baseSelect
  ],
  entity_director_title: [baseRequirement],
  entity_director_first_name: [baseRequirement],
  entity_director_last_name: [
    baseRequirement
  ],
  entity_director_gender: [baseRequirement],
  entity_director_dob: [
    baseRequirement
  ],
  entity_director_nationality: [baseRequirement],
  entity_director_place_of_birth: [baseRequirement],
  entity_director_tax_country: [baseRequirement],
  entity_director_tax_num: [baseRequirement],
  entity_director_address: [baseRequirement],
  entity_director_country: [baseRequirement],
  entity_director_suburb: [baseRequirement],
  entity_director_city: [baseRequirement],
  entity_director_postcode: [baseRequirement],
  entity_director_dialcode: [baseRequirement],
  entity_director_phonenum: [baseRequirement],
  entity_director_email: [emailValidator],
  entity_director_identification_type: [baseRequirement],
  entity_director_id_num: [baseRequirement],
  entity_director_issue_date: [baseRequirement],
  entity_director_expiry_date: [baseRequirement],
  entity_director_issue_country: [baseRequirement],
  entity_director_id_proveChannel: [baseRequirement],
  entity_director_id_proveMethod: [baseRequirement],
  entity_director_nz_au_passport_num: [baseRequirement],
  entity_director_nz_au_passport_country: [baseRequirement],
  entity_director_nz_au_passport_file: [baseRequirement],
  entity_director_nz_au_driver_num: [baseRequirement],
  entity_director_nz_au_driver_country: [baseRequirement],
  entity_director_nz_au_driver_front_file: [baseRequirement],
  entity_director_nz_au_driver_back_file: [baseRequirement],
  entity_director_cn_passport_num: [baseRequirement],
  entity_director_cn_passport_country: [baseRequirement],
  entity_director_cn_passport_file: [baseRequirement],
  entity_director_cn_passport_visa_file: [baseRequirement],
  entity_director_cn_identify_num: [baseRequirement],
  entity_director_cn_identify_country: [baseRequirement],
  entity_director_cn_identify_front_file: [baseRequirement],
  entity_director_cn_identify_back_file: [baseRequirement],
  entity_director_cn_bank_num: [baseRequirement],
  entity_director_cn_bank_country: [baseRequirement],
  entity_director_cn_bank_file: [baseRequirement],
  entity_director_cn_passport_option_num: [baseRequirement],
  entity_director_cn_passport_option_country: [baseRequirement],
  entity_director_cn_passport_option_file: [baseRequirement],
  entity_director_cn_passport_visa_option_file: [baseRequirement],
  entity_director_other_passport_option_num: [baseRequirement],
  entity_director_other_passport_option_country: [baseRequirement],
  entity_director_other_passport_option_file: [baseRequirement],
  entity_director_other_passport_visa_option_file: [baseRequirement],
  entity_director_addressProveType: [
    baseRequirement
  ],
  entity_director_address_file_list: [
    baseRequirement
  ]

}
